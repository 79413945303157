import {patientConstants} from '../constants';
import {List} from 'immutable';

export function patient(state = new List(), action) {
  switch (action.type) {
    case patientConstants.GETPATIENTS_REQUEST:
      return Object.assign({}, state, {
        loading: true,
        items: new List(),
        total: 0,
        perPage: 10,
        page: 1
      });

    case patientConstants.GETPATIENTS_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        items: List(action.patients),
        total: action.total,
        perPage: action.perPage,
        page: action.page
      });

    case patientConstants.GETPATIENTS_FAILURE:
      return Object.assign({}, state, {loading: false, error: action.error});

    default:
      return state
  }
}
